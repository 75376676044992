export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-1QM79SGRS2'
    },
    oauth: {
      clientId: '409352233542-o8ejoeb099eh25svjro70moinje3sm1j.apps.googleusercontent.com'
    }
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoZXFmNDAxbXIybHRmNm92N3Y3bXEifQ.BnHhPxySzTKSL_ywlvsd0w'
  },
  rollbar: {
    accessToken: '688eb23ca4e249708000bee2415352a3'
  }
}